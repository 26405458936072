<template>
  <div>
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <div class="row">
          <ResumeCard
            :number="locations"
            title="Locations (validé(e)s)"
            text-color="text-primary"
            border-color="border-left-primary"
            icon="fas fa-calendar"
          ></ResumeCard>
          <ResumeCard
            :number="ventes"
            title="Ventes (validé(e)s)"
            text-color="text-info"
            border-color="border-left-info"
            icon="fas fa-calendar"
          ></ResumeCard>
          <ResumeCard
            :number="attentes"
            title="Attentes"
            text-color="text-warning"
            border-color="border-left-warning"
            icon="fas fa-calendar"
          ></ResumeCard>
          <ResumeCard
            :number="total"
            title="Total"
            text-color="text-success"
            border-color="border-left-success"
            icon="fas fa-calendar"
          ></ResumeCard>
        </div>
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">Liste des annonces</h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Button-->
              <router-link
                :to="{ name: 'ad.new' }"
                class="btn btn-success font-weight-bolder"
              >
                <i class="la la-plus"></i> Ajouter
              </router-link>
              <!--end::Button-->
            </div>
          </div>
          <b-card-body>
            <!--begin: Datatable-->
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-form-select
                    style="width: 50px"
                    v-model="perPage"
                    :options="options"
                    size="sm"
                  ></b-form-select>
                </b-col>
                <b-col md="6">
                  <b-form-input
                    class="float-right mb-2"
                    style="width: 200px;"
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Rechercher"
                  >
                  </b-form-input>
                </b-col>
              </b-row>
            </b-form>
            <b-row>
              <b-col>
                <b-table
                  bordered
                  hover
                  outlined
                  :items="ads"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :fields="fields"
                  responsive="sm"
                >
                  <template #cell(superficie)="data">
                    <p class="">{{ data.item.superficie }} m<sup>2</sup></p>
                  </template>

                  <template #cell(categorie)="data">
                    <p class="">{{ data.value }}</p>
                  </template>

                  <template #cell(valider)="data">
                    <p class="">{{ data.value }}</p>
                  </template>

                  <template #cell(ville_id)="data">
                    <p class="">{{ data.item.ville.nom }}</p>
                  </template>

                  <template #cell(type_id)="data">
                    <h5>
                      <span class="badge badge-primary text-md-center ">{{
                        data.item.type.nom
                      }}</span>
                    </h5>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <router-link
                      class="btn btn-info btn-sm ml-2"
                      :to="{ name: 'ad.show', params: { id: data.item.id } }"
                    >
                      <i class="fas fa-eye"></i
                    ></router-link>

                    <router-link
                      class="btn btn-warning btn-sm ml-2"
                      :to="{ name: 'ad.edit', params: { id: data.item.id } }"
                    >
                      <i class="fas fa-edit"></i
                    ></router-link>

                    <button
                      v-show="user.roles[0]['name'] === 'ADM'"
                      class="btn btn-danger btn-sm ml-2"
                      @click="remove(data.item)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>

                    <router-link
                      class="btn btn-success btn-sm ml-2"
                      :to="{ name: 'ad.print', params: { id: data.item.id } }"
                    >
                      <i class="fas fa-print"></i
                    ></router-link>
                  </template>
                </b-table>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  :fields="fields"
                  aria-controls="my-table"
                  class="float-right mt-2"
                ></b-pagination>
              </b-col>
            </b-row>
            <!--end: Datatable-->
          </b-card-body>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import ResumeCard from "@/components/ResumeCard";
import { mapGetters } from "vuex";

export default {
  name: "Index",
  components: {
    ResumeCard
  },
  data() {
    return {
      create: "ad/create",
      source: "ad",
      resource: "ad",
      ads: [],
      attentes: 0,
      actives: 0,
      locations: 0,
      ventes: 0,
      total: 0,
      // DataTable option
      filter: "",
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "id",
          label: "#"
        },
        {
          key: "code",
          label: "Réference"
        },
        {
          key: "superficie",
          label: "Superficie"
        },
        {
          key: "categorie",
          label: "Categorie",
          formatter: value => {
            return value.charAt(0).toUpperCase() + value.slice(1);
          }
        },
        {
          key: "valider",
          label: "Validé(e)?",
          formatter: value => {
            return value === 1 ? String("Validé(e)") : String("Non validé(e)");
          }
        },
        {
          key: "ville_id",
          label: "Ville"
        },
        {
          key: "type_id",
          label: "Type"
        },
        "actions"
      ],
      options: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" }
      ]
    };
  },
  beforeMount() {
    this.fetchData();
    document.title = "TOGOHOME | Liste des annonces";
  },
  methods: {
    fetchData() {
      let vm = this;
      ApiService.get(this.source).then(function(response) {
        vm.ads = response.data.annonces;
        vm.total = response.data.annonces.length;
        vm.attentes = response.data.attentes;
        vm.actives = response.data.actives;
        vm.locations = response.data.locations;
        vm.ventes = response.data.ventes;
      });
    },
    remove(ad) {
      let vm = this;
      Swal.fire({
        title: "Vous êtes sûr de vouloir supprimer l'annonce " + ad.code + "?",
        text: "Il n'y aura aucun retour en arrière possible !",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: "Annuler",
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        }
      })
        .then(function(isConfirm) {
          if (isConfirm.isConfirmed) {
            ApiService.delete(`${vm.resource}/${ad.id}`)
              .then(response => {
                if (response.data.success) {
                  Swal.fire({
                    title: "Success",
                    text: response.data.message,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 3000,
                    showClass: {
                      popup: "animate__animated animate__fadeInDown"
                    },
                    hideClass: {
                      popup: "animate__animated animate__fadeOutUp"
                    }
                  });
                  vm.fetchData();
                } else {
                  Swal.fire({
                    title: "Ooups!",
                    text: "Erreur, impossible de supprimé l'annonce.",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 3000,
                    showClass: {
                      popup: "animate__animated animate__fadeInDown"
                    },
                    hideClass: {
                      popup: "animate__animated animate__fadeOutUp"
                    }
                  });
                }
              })
              .catch(error => {
                Swal.fire({
                  title: "Ooups!",
                  text: error,
                  icon: "error",
                  showConfirmButton: false,
                  timer: 5000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown"
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp"
                  }
                });
              });
          } else {
            Swal.fire({
              title: "Ooups!",
              text: "Annonce non supprimée :)",
              icon: "error",
              showConfirmButton: false,
              timer: 3000,
              showClass: {
                popup: "animate__animated animate__fadeInDown"
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp"
              }
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  computed: {
    rows() {
      return this.ads.length;
    },
    ...mapGetters({
      user: "user"
    })
  }
};
</script>

<style scoped></style>
